<template>
  <Card class="p-25">
    <h2 class="h5 font-bold text-center">
      {{ $t(`rewards.landing.rewards_we_offer.${currencyType}`) }}
    </h2>

    <div class="flex justify-center items-center">
      <button
        class="w-40 h-40 flex justify-center items-center cursor-pointer shadow-lg rounded-full flex-shrink-0"
        @click="scrollBack"
      >
        <Icon iconClass="fas fa-chevron-left" />
      </button>
      <div
        id="carousel-items"
        class="overflow-x-hidden mt-25 carousel-items flex lg:w-800 md:w-600 sm:w-400 w-200 flex-shrink-0"
      >
        <RewardCarouselItem
          v-for="(reward, idx) in rewardsCarousel"
          :id="`carousel-item-${idx}`"
          :key="`carousel-item-${idx}`"
          :imageUrl="reward.imageUrl"
          :name="reward.name"
          :denominations="reward.denominations"
        />
      </div>
      <button
        class="w-40 h-40 flex justify-center items-center cursor-pointer shadow-lg rounded-full flex-shrink-0"
        @click="scrollNext"
      >
        <Icon iconClass="fas fa-chevron-right" />
      </button>
    </div>
  </Card>
</template>

<script>
import { Card } from '@components/common/card/index'
import RewardCarouselItem from './RewardCarouselItem.vue'
import { mapGetters } from 'vuex'

export default {
  components: { Card, RewardCarouselItem },
  data() {
    return {
      currentCarouselIndex: 0,
    }
  },
  computed: {
    ...mapGetters('rewards', ['rewardsCarousel', 'currencyType']),
  },
  methods: {
    updateScrollPosition() {
      const carousel = document.getElementById('carousel-items')
      this.scrolledToEnd =
        carousel.scrollLeft === carousel.scrollWidth - carousel.clientWidth
    },

    scrollToIndex(index) {
      window.document.querySelector(`#carousel-item-${index}`).scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      })
    },

    scrollNext() {
      const carousel = document.getElementById('carousel-items')
      const isScrolledToEnd =
        carousel.scrollLeft === carousel.scrollWidth - carousel.clientWidth

      if (isScrolledToEnd) {
        this.currentCarouselIndex = -1
      }
      this.scrollToIndex(++this.currentCarouselIndex)
    },

    scrollBack() {
      // this.updateScrollPosition()

      if (this.currentCarouselIndex === 0) {
        this.currentCarouselIndex =
          this.rewardsCarousel.length -
          (document.getElementById('carousel-items').clientWidth / 200 - 1)
      }
      this.scrollToIndex(--this.currentCarouselIndex)
    },
  },
}
</script>

<style scoped>
.carousel-items {
  scroll-snap-type: x mandatory;
}
</style>
