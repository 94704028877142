<template>
  <div
    class="w-full max-w-3xl md:w-435 max-md:w-screen h-full bg-white overflow-auto border-l"
  >
    <div class="px-30 py-20 border-b border-grey-200">
      <div class="flex items-center justify-between">
        <h3>{{ $t('rewards.family_rewards.view') }}</h3>
        <CloseIcon @click="$emit('closeDrawer')" />
      </div>
    </div>
    <div class="px-30">
      <div
        v-for="{ dependentId, name, rewardsAvailable } in familyRewards"
        :key="dependentId"
        class="py-20 border-b border-grey-200 flex items-center justify-between"
      >
        <div class="flex-grow">
          <h6>{{ $t(name) }}</h6>
          <p v-if="rewardsAvailable > 0" class="font-semibold">
            <span>{{ $t('rewards.family_rewards.available_rewards') }}</span>
            <span class="text-green-500'">${{ rewardsAvailable }}</span>
          </p>
          <p v-else class="font-semibold text-grey-500">
            {{ $t('rewards.family_rewards.no_rewards_available') }}
          </p>
        </div>
        <div v-if="rewardsAvailable > 0">
          <router-link :to="{ name: 'rewards-redeem', query: { dependentId } }">
            <HMButton rounded="full" size="x-small">
              {{ $t('rewards.family_rewards.redeem') }}
            </HMButton>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CloseIcon from '@components/common/CloseIcon'
export default {
  components: { CloseIcon },
  computed: {
    ...mapGetters('rewards', ['familyRewards']),
  },
}
</script>
