<template>
  <keep-alive>
    <router-view />
  </keep-alive>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  created() {
    this.initRewards()
  },
  methods: {
    ...mapActions('rewards', ['initRewards']),
  },
}
</script>

<style></style>
