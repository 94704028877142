<template>
  <Card class="p-25">
    <div class="md:flex justify-between items-center md:space-y-0 space-y-20">
      <div>
        <h2 class="font-bold h5">
          {{ $t('rewards.automatic_rewards.title') }}
        </h2>

        <div v-if="activeAutoReward" class="mt-10 flex items-center">
          <img
            aria-hidden="true"
            :src="activeAutoReward.imageUrl"
            :alt="activeAutoReward.name"
            class="self-center h-40 mr-20 rounded"
          />
          <p class="break-words">{{ rewardName }} - {{ deliveryForm }}</p>
        </div>
        <Body v-else>
          {{ $t('rewards.landing.no_automatic_rewards') }}
        </Body>
      </div>

      <div v-if="activeAutoReward" class="flex">
        <HMButton color="red" class="mr-8" @click="showModal">
          {{ $t('rewards.automatic_rewards.deactivate') }}
        </HMButton>
        <HMButton outlined color="white" :to="{ name: 'auto-rewards-setup' }">
          {{ $t('common.edit') }}
        </HMButton>
      </div>
      <HMButton
        v-else
        outlined
        color="white"
        :to="{ name: 'auto-rewards-splash' }"
      >
        {{ $t('rewards.automatic_rewards.activate') }}
      </HMButton>
    </div>

    <!-- confirm deactivation modal -->
    <Modal :active="showDeactivateModal" @close="hideModal">
      <div class="flex-col justify-center p-20 w-full sm:w-300 text-center">
        <p class="text-lg">
          {{ $t('rewards.automatic_rewards.confirm_deactivate') }}
        </p>
        <div class="space-y-10 mt-20">
          <HMButton color="red" @click="deactivateAutoReward">
            {{ $t('rewards.automatic_rewards.deactivate') }}
          </HMButton>
          <HMButton color="white" outlined @click="hideModal">
            {{ $t('common.cancel_btn') }}
          </HMButton>
        </div>
      </div>
    </Modal>
  </Card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Card } from '@components/common/card/index'
import Modal from '@components/common/Modal'

export default {
  components: { Card, Modal },
  data() {
    return {
      showDeactivateModal: false,
    }
  },
  computed: {
    ...mapGetters('rewards', ['activeAutoReward', 'autoRewardPreferenceId']),
    rewardName() {
      return this.$t('rewards.automatic_rewards.confirmation.gift_card', [
        this.activeAutoReward.name,
      ])
    },
    deliveryForm() {
      return this.$t(
        `rewards.automatic_rewards.confirmation.${this.activeAutoReward.deliveryForm}`
      )
    },
  },
  methods: {
    ...mapActions('rewards', ['turnOffAutoReward']),
    showModal() {
      this.showDeactivateModal = true
    },
    hideModal() {
      this.showDeactivateModal = false
    },
    deactivateAutoReward() {
      this.turnOffAutoReward(this.autoRewardPreferenceId).then(this.hideModal)
    },
  },
}
</script>
