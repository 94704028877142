<template>
  <div v-if="transaction.amount !== 0" class="py-16">
    <div class="flex">
      <div class="flex-1">
        <p>{{ rewardTypeText }}:</p>
        <p>{{ rewardTitle }} {{ rewardQuantityText }}</p>

        <p v-if="transactionMemberText">{{ transactionMemberText }}</p>
        <div v-if="shippingAddress" class="mt-10">
          <p>{{ $t('rewards.landing.shipping_address') }}:</p>
          <p>{{ shippingAddress.line1 }} {{ shippingAddress.line2 }}</p>
        </div>
        <div v-if="transaction.shipper" class="mt-10">
          <p>{{ $t('rewards.landing.shipper') }}</p>
          <p>
            <span>{{ transaction.shipper }}</span>
          </p>
        </div>

        <div v-if="transaction.trackingNumber" class="mt-10">
          <p>{{ $t('rewards.landing.tracking_number') }}</p>
          <p>{{ transaction.trackingNumber }}</p>
        </div>

        <a
          v-if="transaction.trackingLink"
          :href="transaction.trackingLink"
          target="_blank"
          class="inline-block mt-10"
        >
          <HMButton size="x-small">Track My Order</HMButton>
        </a>
      </div>

      <span
        class="text-lg self-center font-bold"
        :class="{
          'text-green-500': transaction.amount > 0,
          'text-red-500': transaction.amount < 0,
        }"
      >
        {{
          transaction.unitOfMeasure === 'points' &&
          !['REDEEM_DIRECT_INCENTIVE', 'REWARD_DIRECT_INCENTIVE'].includes(
            transaction.type
          )
            ? $t('rewards.points', [rewardAmountText])
            : rewardAmountText
        }}
      </span>
    </div>
  </div>
</template>

<script>
import _get from 'lodash/fp/get'
import rewardsCurrency from '@platform-shared/mixins/rewardsCurrency'

export default {
  mixins: [rewardsCurrency],
  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isRedemption() {
      return (
        this.transaction.type === 'REDEEM' ||
        this.transaction.type === 'REDEEM_DIRECT_INCENTIVE'
      )
    },
    rewardTypeText() {
      switch (this.transaction.type) {
        case 'REDEEM_REWARD_BOX_TOKEN':
        case 'REDEEM_DIRECT_INCENTIVE':
        case 'REDEEM':
          return this.$t('rewards.landing.redemption')
        case 'REWARD':
        case 'REWARD_REWARD_BOX_TOKEN':
        case 'REWARD_DIRECT_INCENTIVE':
        default:
          return this.$t('rewards.landing.reward')
      }
    },
    rewardTitle() {
      const isMerchandise =
        _get('transaction.incentiveProductType', this) === 'MERCHANDISE'

      const amount = this.isRedemption
        ? `$${this.transaction.faceValue * this.transaction.completedCount}`
        : ''

      const description = ` ${this.transaction.description}`
      const type = this.isRedemption && !isMerchandise ? ' Gift Card' : ''
      const deliveryForm =
        (this.transaction.type === 'REDEEM' ||
          this.transaction.type === 'REDEEM_DIRECT_INCENTIVE') &&
        !isMerchandise
          ? this.transaction.deliveryForm === 'digital'
            ? ' (Digital)'
            : ' (Physical)'
          : ''
      return `${amount}${description}${type}${deliveryForm}`
    },
    rewardQuantityText() {
      return this.transaction.completedCount > 1
        ? `x${this.transaction.completedCount}`
        : ''
    },
    shippingAddress() {
      return this.transaction.shippingAddress
    },
    rewardAmountText() {
      const amount = this.transaction.amount
      const isMultiple = this.transaction.amount > 1

      const amountText = isMultiple ? `${amount} ` : ''
      const appendText = isMultiple ? 'es' : ''
      const rewardBox = this.$t('rewards.reward_boxes.reward_box')
      const rewardBoxText = `${amountText}${rewardBox}${appendText}`

      switch (this.transaction.type) {
        case 'REDEEM_REWARD_BOX_TOKEN':
          return `-${rewardBoxText}`
        case 'REWARD_REWARD_BOX_TOKEN':
          return `+${rewardBoxText}`
        case 'REDEEM_DIRECT_INCENTIVE':
          return `-${this.formatCurrency(Math.abs(amount), 'dollars')}`
        case 'REWARD_DIRECT_INCENTIVE':
          return `+${this.formatCurrency(Math.abs(amount), 'dollars')}`
        case 'REDEEM':
          return `-${this.formatCurrency(
            Math.abs(amount),
            this.transaction.unitOfMeasure
          )}`
        case 'REWARD':
          return `+${this.formatCurrency(
            Math.abs(amount),
            this.transaction.unitOfMeasure
          )}`
        default:
          return `${amount < 0 ? '-' : '+'}${this.formatCurrency(
            Math.abs(amount),
            this.transaction.unitOfMeasure
          )}`
      }
    },
    transactionMemberText() {
      const { memberName, type } = this.transaction
      return memberName
        ? type.includes('REDEEM')
          ? this.$t('rewards.history.redeemed_for', [memberName])
          : this.$t('rewards.history.awarded_to', [memberName])
        : ''
    },
  },
}
</script>

<style></style>
