<template>
  <button class="flex justify-end p-8 cursor-pointer" @click="handleClick">
    <p class="text-base">{{ $t('rewards.reward_selection.a_z') }}</p>
    <Icon
      :title="$t('rewards.reward_selection.a_z')"
      class="px-10 self-center"
      :iconClass="{
        'fas fa-angle-down': isSortedAsc,
        'fas fa-angle-up': !isSortedAsc,
      }"
      :srText="srText"
    />
  </button>
</template>
<script>
export default {
  data: function() {
    return {
      isSortedAsc: true,
    }
  },
  computed: {
    srText() {
      return this.isSortedAsc
        ? this.$t('common.ascending')
        : this.$t('common.descending')
    },
  },
  methods: {
    handleClick() {
      this.isSortedAsc = !this.isSortedAsc
      this.$emit('sort')
    },
  },
}
</script>
