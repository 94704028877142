<template>
  <div class="py-45 px-40 min-w-xs max-w-md">
    <h4 class="font-normal mb-20">{{ $t('rewards.edit_info.title') }}</h4>
    <Form :validator="$v.model" :error="error">
      <FormInput
        v-model="model.streetAddr1"
        class="pb-5"
        name="streetAddr1"
        :label="$t('rewards.edit_info.street_address_1')"
        placeholder="1234 Main Street"
        autocomplete="address-line-1"
      />
      <FormInput
        v-model="model.streetAddr2"
        class="py-5"
        name="streetAddr2"
        :label="$t('rewards.edit_info.street_address_2')"
        placeholder="Apt or Building Number"
        :required="false"
        autocomplete="address-line-2"
      />
      <FormInput
        v-model="model.zip"
        class="py-5 w-5/12"
        name="zip"
        :label="$t('rewards.edit_info.zip_code')"
        placeholder="77777"
        autocomplete="postal-code"
      />
      <div class="flex">
        <FormInput
          v-model="model.city"
          class="pr-10 py-5"
          name="city"
          :label="$t('rewards.edit_info.city')"
          placeholder="New York"
          autocomplete="address-level2"
        />
        <FormListPicker
          v-model="model.state"
          class="py-5 flex-1 pr-4"
          name="state"
          :placeholder="$t('rewards.edit_info.choose_state')"
          :label="$t('rewards.edit_info.state')"
          :data="states"
          autocomplete="address-level1"
        />
      </div>
    </Form>
    <HMButton class="mt-20 w-full" @click="updateAddress()">
      {{ $t('common.save_btn') }}
    </HMButton>
    <HMButton
      color="white"
      size="small"
      class="border mt-10 w-full"
      @click="$emit('close')"
    >
      {{ $t('common.cancel_btn') }}
    </HMButton>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import Form from '../../common/forms/Form'
import FormInput from '../../common/forms/FormInput'
import { STATES } from '@platform-shared/constants'
import FormListPicker from '../../common/forms/FormListPicker'
export default {
  components: { FormListPicker, Form, FormInput },
  props: {
    autoReward: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    states: STATES,
    model: {
      streetAddr1: '',
      streetAddr2: '',
      zip: '',
      city: '',
      state: null,
    },
    error: null,
    value: null,
    options: STATES,
  }),
  validations: {
    model: {
      streetAddr1: { required },
      streetAddr2: {},
      zip: { required },
      city: { required },
      state: { required },
    },
  },
  computed: {
    ...mapGetters('rewards', ['deliveryAddress', 'autoRewardDeliveryAddress']),
  },
  created() {
    const address = this.autoReward
      ? this.autoRewardDeliveryAddress
      : this.deliveryAddress

    this.model.streetAddr1 = address.streetAddr1
    this.model.streetAddr2 = address.streetAddr2
    this.model.zip = address.zip
    this.model.city = address.city
    this.model.state = address.state
  },
  methods: {
    ...mapActions('rewards', [
      'updateDeliveryAddress',
      'updateAutoRewardDeliveryAddress',
    ]),
    updateAddress() {
      this.error = null
      this.$v.$touch()
      if (this.$v.$invalid) return
      if (this.autoReward) {
        this.updateAutoRewardDeliveryAddress(this.model)
      } else {
        this.updateDeliveryAddress(this.model)
      }
      this.$emit('close')
    },
  },
}
</script>
<style></style>
