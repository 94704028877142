<template>
  <div class="hm-form-list-picker">
    <FormGroup
      v-slot="{ hasError }"
      :label="label"
      :name="name"
      :helperText="helperText"
    >
      <select
        :id="name"
        :required="required"
        class="w-full border border-grey-500 rounded bg-white text-base leading-none focus:outline-none focus:shadow-outline py-12 px-8 placeholder-grey-500"
        :value="value"
        :autocomplete="autocomplete"
        @input="(e) => $emit('input', e.target.value)"
      >
        <option v-for="opt in data" :key="opt.id" :value="opt.value">
          {{ opt.display }}
        </option>
      </select>
    </FormGroup>
  </div>
</template>

<script>
import FormGroup from './FormGroup'
import bindAll from '@platform-shared/mixins/bindAll'
import _find from 'lodash/find'
export default {
  components: { FormGroup },
  mixins: [bindAll],
  props: {
    data: { type: Array, required: true },
    placeholder: { type: String, default: '' },
    textClass: { type: String, default: '' },
    label: { type: String, default: '' },
    name: { type: String, required: true },
    hasError: { type: Boolean },
    selectLabel: { type: String, default: '' },
    compact: { type: Boolean, default: false },
    required: { type: Boolean, default: true },
    value: { type: [String, Number], required: true },
    autocomplete: { type: String, default: null },
    helperText: {
      type: String,
      default: '',
    },
  },
  computed: {
    display() {
      if (this.compact) return this.$attrs.value
      const selected = _find(this.data, { value: this.$attrs.value })
      return selected ? selected.display : null
    },
  },
}
</script>
