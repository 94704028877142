<template>
  <h2 class="h5">
    {{ $t(`rewards.landing.current_balance.title.${currencyType}`) }}:
    <span class="text-green-500">
      {{ currentBalanceText }}
    </span>
  </h2>
</template>

<script>
import { mapGetters } from 'vuex'
import rewardsCurrency from '@platform-shared/mixins/rewardsCurrency'

export default {
  mixins: [rewardsCurrency],
  computed: {
    ...mapGetters('rewards', ['currentRewardsSummary']),

    availablePoints() {
      const { availablePoints = 0 } = this.currentRewardsSummary
      return availablePoints
    },

    currentBalanceText() {
      return this.$t(
        `rewards.landing.current_balance.value.${this.currencyType}`,
        [this.availablePoints]
      )
    },
  },
}
</script>
