<template>
  <ExpandableCard :id="elementId" :defaultExpanded="expanded">
    <template #title>
      <h2 class="h5 font-bold">
        {{ $t(`rewards.landing.how_it_works.${currencyType}.title`) }}
      </h2>
    </template>
    <div class="space-y-25">
      <div class="flex justify-center w-full max-w-xl mx-auto">
        <img
          :src="
            require('@assets/images/rewards/how-it-works/overview_flow.png')
          "
        />
      </div>
      <p>
        {{ $t(`rewards.landing.how_it_works.${currencyType}.description`) }}
      </p>

      <div
        v-for="step in steps"
        :key="step.title"
        class="space-y-25 lg:flex lg:items-center lg:space-y-0"
      >
        <div class="flex-1 flex justify-center">
          <img :src="step.imageSrc" class="w-300 object-contain" />
        </div>
        <div class="flex-1">
          <Body class="h3 font-bold">{{ step.title }}</Body>
          <Body class="mt-8">{{ step.body }}</Body>
        </div>
      </div>
      <div class="flex justify-center mt-20">
        <HMButton :to="{ name: 'home' }">
          {{ $t(`rewards.landing.how_it_works.${currencyType}.start_earning`) }}
        </HMButton>
      </div>
    </div>
  </ExpandableCard>
</template>

<script>
import _get from 'lodash/get'
import { mapGetters } from 'vuex'
import { ExpandableCard } from '@components/common/card/index'

export default {
  components: { ExpandableCard },
  data() {
    return {
      elementId: 'how-it-works',
    }
  },
  computed: {
    ...mapGetters('rewards', ['currencyType']),
    expanded() {
      return _get(this, '$route.hash', false) === `#${this.elementId}`
    },
    steps() {
      const root = `rewards.landing.how_it_works.${this.currencyType}.steps`
      return [
        {
          title: this.$t(`${root}.1.title`),
          body: this.$t(`${root}.1.description`),
          imageSrc: require('@assets/images/rewards/how-it-works/person_health_activities.png'),
        },
        {
          title: this.$t(`${root}.2.title`),
          body: this.$t(`${root}.2.description`),
          imageSrc: require('@assets/images/rewards/how-it-works/complete_to_earn.png'),
        },
        {
          title: this.$t(`${root}.3.title`),
          body: this.$t(`${root}.3.description`),
          imageSrc: require('@assets/images/rewards/how-it-works/biggest_rewards.png'),
        },
        {
          title: this.$t(`${root}.4.title`),
          body: this.$t(`${root}.4.description`),
          imageSrc: require('@assets/images/rewards/how-it-works/redeem_now.png'),
        },
      ]
    },
  },
}
</script>
