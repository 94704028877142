<template>
  <div class="w-full max-w-4xl py-20 bg-white">
    <Back
      :route="{ name: 'rewards' }"
      title="common.back_to_rewards"
      class="my-15 block"
    />
    <h4 class="mt-16">
      {{ $t('rewards.reward_boxes.selection.title') }}
    </h4>
    <div>
      <div
        v-for="rewardBox in rewardBoxesAvailable"
        :key="rewardBox.id"
        class="border rounded-lg mt-20 px-40 py-20 flex flex-wrap justify-center"
      >
        <div>
          <img class="w-300 m-12" :src="rewardBox.imageUrl" />
        </div>
        <div
          class="flex flex-1 flex-col justify-center items-center min-w-xs m-12"
        >
          <h4 class="mb-20">{{ rewardBox.name }}</h4>
          <Markdown :source="rewardBox.description" class="mb-30" />
          <HMButton rounded="full" @click="redeemBundle(rewardBox)">
            {{ $t('rewards.reward_boxes.selection.redeem_btn') }}
          </HMButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Markdown from '../../../common/Markdown'

export default {
  components: { Markdown },
  computed: {
    ...mapGetters('rewards', ['rewardBoxesAvailable', 'currentRewardsSummary']),
  },
  methods: {
    ...mapActions('rewards', ['selectRewardBox', 'clearSelectedRewardBox']),
    redeemBundle(bundle) {
      this.selectRewardBox(bundle).then(() =>
        this.$router.push({ name: 'reward-boxes-confirmation' })
      )
    },
  },
  mounted() {
    if (!this.currentRewardsSummary.availableTokens) {
      this.$router.push({ name: 'rewards' })
    }
  },
}
</script>

<style></style>
