<template>
  <div class="w-full max-w-xl p-20">
    <div class="mb-100 space-y-20">
      <!-- title -->
      <h2 class="mt-5">{{ $t('rewards.reward_selection.title') }}</h2>

      <!-- description -->
      <p>
        {{
          hasHome
            ? $t('rewards.reward_selection.description')
            : $t('rewards.reward_selection.short_description')
        }}
      </p>

      <!-- sorter -->
      <div class="flex justify-end">
        <AZSorter @sort="isSortedAsc = !isSortedAsc" />
      </div>

      <!-- reward section -->
      <div class="space-y-35">
        <div
          v-for="(rewardGroup, rewardGroupIndex) in sortedRewards"
          :key="`redeem-reward-group-${rewardGroupIndex}`"
        >
          <!-- points header -->
          <div
            v-if="sortedRewards.length > 1"
            class="flex justify-between"
            :class="{
              'text-grey-500': currentBalance < rewardGroup[0].redemptionAmount,
            }"
          >
            <h2 class="font-bold text-2xl">
              {{
                $t('rewards.landing.current_balance.value.dollars', [
                  rewardGroup[0].faceValue,
                ])
              }}
            </h2>
            <p>
              {{ $t('rewards.points', [rewardGroup[0].redemptionAmount]) }}
              <span
                v-if="rewardGroupIndex === sortedRewards.length - 1"
                class="border-2 border-green-500 bg-green-100 rounded px-4 py-2 text-green-500 font-bold ml-8 text-sm"
              >
                {{ $t('rewards.reward_selection.best_value') }}
              </span>
            </p>
          </div>

          <!-- reward selector -->
          <GiftCardSelector
            v-for="reward in rewardGroup"
            :key="`${reward.name}${reward.faceValue}`"
            :reward="reward"
            :disabled="currentBalance < reward.redemptionAmount"
          />
        </div>
      </div>
    </div>

    <!-- footer -->
    <div
      class="fixed bottom-0 right-0 w-screen bg-white py-25 px-40 flex justify-between items-center border-t"
      role="region"
      :aria-label="$t('rewards.reward_selection.rewards_navigation')"
    >
      <HMButton color="white" class="w-200" outlined @click="handleCancel">
        {{ $t('common.cancel_btn') }}
      </HMButton>

      <div
        v-if="canAffordRedemption || healthActionCompletionId"
        class="flex items-center"
      >
        <h5 class="text-lg text-green-500 mr-8">
          <span v-if="redeemingMember.isDependent">
            {{ redeemingMember.name }} -
          </span>
          {{
            $t(
              `rewards.reward_selection.remaining.${
                healthActionCompletionId ? 'dollars' : currencyType
              }`,
              [redeemingMember.remainingMoney, redeemingMember.availablePoints]
            )
          }}
        </h5>

        <p v-if="basketCount > 0">
          ({{
            basketCount === 1
              ? $t('rewards.reward_selection.card_count')
              : $t('rewards.reward_selection.cards_count', [basketCount])
          }})
        </p>
      </div>

      <HMButton class="w-200" :disabled="basketCount === 0" @click="handleNext">
        {{ $t('common.next_btn') }}
      </HMButton>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import GiftCardSelector from './GiftCardSelector'
import _toLower from 'lodash/toLower'
import _sumBy from 'lodash/sumBy'
import _orderBy from 'lodash/orderBy'
import _get from 'lodash/get'
import AZSorter from '../../common/AZSorter'
import { FEATURES } from '@platform-shared/constants'
import rewardsCurrency from '@platform-shared/mixins/rewardsCurrency'

export default {
  name: 'RedeemPage',
  components: { AZSorter, GiftCardSelector },
  mixins: [rewardsCurrency],
  beforeRouteEnter(to, from, next) {
    next((component) => {
      component.resetBasket()
      if (component.dependentId) {
        component.setDependentForRedemption(component.dependentId)
      }
    })
  },
  props: {
    dependentId: {
      type: Number,
      default: undefined,
    },
  },

  data() {
    return {
      isSortedAsc: true,
    }
  },
  computed: {
    ...mapGetters('rewards', [
      'memberForRedemption',
      'basket',
      'redeemableRewards',
      'remainingMoney',
      'canAffordRedemption',
      'directIncentivePointSummaryByHealthActionCompletionId',
    ]),
    ...mapGetters('client', ['hasFeature']),
    hasHome() {
      return this.hasFeature(FEATURES.HOME)
    },
    basketCount() {
      return _sumBy(this.basket, 'quantity')
    },
    healthActionCompletionId() {
      return Number(this.$route.query.hacId)
    },
    pointSummaries() {
      return this.directIncentivePointSummaryByHealthActionCompletionId(
        this.healthActionCompletionId
      )
    },
    healthActionId() {
      return _get(this.pointSummaries, [0, 'healthActionId'])
    },
    rewardCatalog() {
      return this.redeemableRewards(this.healthActionId)
    },
    sortedRewards() {
      const sortOrder = this.isSortedAsc ? 'asc' : 'desc'

      // TODO: once the redemption process is the same across points and dollars, we can just use the first map/orderby here
      if (this.usesPoints) {
        return this.rewardCatalog.map((group) =>
          _orderBy(group, [(reward) => _toLower(reward.name)], [sortOrder])
        )
      } else {
        return this.rewardCatalog.map((group) =>
          _orderBy(
            group,
            ['faceValue', (reward) => _toLower(reward.name)],
            [sortOrder, sortOrder]
          )
        )
      }
    },
    currentBalance() {
      return this.remainingMoney(this.healthActionCompletionId)
    },
    redeemingMember() {
      return this.memberForRedemption(this.healthActionCompletionId)
    },
  },
  watch: {
    healthActionId(healthActionId) {
      if (healthActionId) {
        this.getIncentiveCatalogForHealthAction(this.healthActionId)
      }
    },
  },
  mounted() {
    if (this.healthActionCompletionId) {
      this.getIncentiveCatalogForHealthAction(this.healthActionId)
    }
  },
  methods: {
    ...mapActions('rewards', [
      'resetBasket',
      'setDependentForRedemption',
      'getIncentiveCatalogForHealthAction',
    ]),
    handleCancel() {
      this.resetBasket()
      this.$router.push('/rewards/')
    },
    handleNext() {
      this.$router.push({
        path: '/rewards/confirmation',
        query: { ...this.$route.query },
      })
    },
  },
}
</script>
