<template>
  <div
    class="bg-grey-100 w-screen flex flex-wrap justify-center"
    style="margin-left: 50%; transform: translateX(-50%);"
  >
    <div class="w-400 m-16">
      <p class="font-bold">
        {{ $t('rewards.reward_boxes.banner.congrats') }}
      </p>
      <p class="mb-30 font-bold">
        {{
          $t(
            availableTokens === 1
              ? 'rewards.reward_boxes.banner.description_single'
              : 'rewards.reward_boxes.banner.description_multiple',
            [availableTokens]
          )
        }}
      </p>
      <router-link :to="{ name: 'reward-boxes-selection' }">
        <HMButton :disabled="!availableTokens" rounded="full">
          {{ $t('rewards.reward_boxes.banner.pick_btn') }}
        </HMButton>
      </router-link>
    </div>
    <div class="m-16">
      <img class="h-200" :src="randomRewardBox.imageUrl" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import _shuffle from 'lodash/shuffle'

export default {
  computed: {
    ...mapGetters('rewards', ['rewardBoxesAvailable', 'currentRewardsSummary']),
    availableTokens() {
      return this.currentRewardsSummary.availableTokens
    },
    randomRewardBox() {
      return _shuffle(this.rewardBoxesAvailable)[0]
    },
  },
}
</script>

<style></style>
