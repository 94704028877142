<template>
  <div
    class="flex flex-col items-center text-center whitespace-pre-wrap w-200 flex-shrink-0 carousel-item"
  >
    <img :src="imageUrl" class="w-80" />
    <Body class="font-bold">{{ name }}</Body>
    <Body>{{ formattedDenominations }}</Body>
  </div>
</template>

<script>
export default {
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    denominations: {
      type: Array,
      required: true,
    },
  },
  computed: {
    formattedDenominations() {
      return this.denominations.map((d) => `$${d}`).join(', ')
    },
  },
}
</script>

<style scoped>
.carousel-item {
  scroll-snap-align: start;
}
</style>
