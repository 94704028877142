<template>
  <FormMessage :text="text" color="bg-red-600" />
</template>
<script>
import FormMessage from './FormMessage.vue'
export default {
  components: { FormMessage },
  props: {
    text: {
      type: String,
      default: 'common.failure',
    },
  },
}
</script>
