<template>
  <div class="w-full max-w-3xl py-20 bg-white mb-100">
    <div>
      <h2 class="mt-5 mb-15">
        {{
          activeAutoReward
            ? $t('rewards.automatic_rewards.selection.title_edit')
            : $t('rewards.automatic_rewards.title')
        }}
      </h2>
      <p class="text md:pr-60">
        {{ $t('rewards.automatic_rewards.selection.description') }}
      </p>
      <div class="flex justify-end">
        <AZSorter @sort="isSortedAsc = !isSortedAsc" />
      </div>
      <GiftCardSelector
        v-for="(reward, index) in sortedRewards"
        :key="`${index}${reward.name}`"
        :reward="reward"
        :autoReward="true"
      />
    </div>

    <div
      class="fixed bottom-0 right-0 w-screen bg-black py-25 px-40 flex flex-row-reverse flex-wrap items-center"
    >
      <div class="w-200 py-8 ml-25 max-md:m-auto">
        <HMButton
          class="w-full"
          :color="selectedAutoReward.id ? 'green' : 'light-grey'"
          :disabled="!selectedAutoReward.id"
          @click="$router.push('/rewards/auto/confirm')"
          >{{ $t('common.next_btn') }}
        </HMButton>
      </div>
      <div class="w-200 mr-auto max-md:m-auto">
        <HMButton
          color="white"
          class="w-full"
          @click="$router.push('/rewards')"
        >
          {{ $t('common.cancel_btn') }}
        </HMButton>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import AZSorter from '../../common/AZSorter'
import _orderBy from 'lodash/orderBy'
import _toLower from 'lodash/toLower'
import GiftCardSelector from './GiftCardSelector'

export default {
  components: { AZSorter, GiftCardSelector },
  data() {
    return {
      isSortedAsc: true,
    }
  },
  computed: {
    ...mapGetters('rewards', [
      'activeAutoReward',
      'groupedAutoRewards',
      'selectedAutoReward',
    ]),
    sortedRewards() {
      const sortOrder = this.isSortedAsc ? 'asc' : 'desc'
      return _orderBy(
        this.groupedAutoRewards,
        [(reward) => _toLower(reward.name)],
        [sortOrder]
      )
    },
  },
  created: function() {
    this.resetSelectedAutoReward()
  },
  methods: {
    ...mapActions('rewards', ['resetSelectedAutoReward']),
  },
}
</script>
