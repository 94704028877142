<template>
  <div class="px-20 max-w-xl">
    <div class="pageOrderSuccess">
      <div class="rounded-lg p-16 bg-white-95 text-center m-25 p-45">
        <h3>{{ $t('rewards.order_ok.title') }}</h3>
        <div v-if="isRewardBox">
          <p class="text-base">
            {{ $t('rewards.reward_boxes.order_ok.description_1') }}
          </p>
          <br />
          <p class="text-base">
            {{ $t('rewards.reward_boxes.order_ok.description_2') }}
          </p>
        </div>
        <div v-else>
          <p class="text-base">
            {{ $t('rewards.order_ok.subtitle') }}
          </p>
          <br />
          <p class="text-base text-left">
            {{ $t('rewards.order_ok.note') }}
          </p>
          <br />
          <p class="text-base text-left">
            <ul class="list-disc">
              <li v-if="containsPhysicalGiftCard">
                {{ $t('rewards.order_ok.physical_gift_card_note') }}
              </li>
              <li v-if="containsDigitalGiftCard">
                {{ $t('rewards.order_ok.digital_gift_card_note') }}
              </li>
              <li v-if="containsMerchandise">
                {{ $t('rewards.order_ok.merchandise_note') }}
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div>
    <HMButton class="w-full" @click="$router.push('/rewards')">
      {{ $t('common.done_btn') }}
    </HMButton>
  </div>
</template>
<script>
export default {
  props: {
    isRewardBox: {
      type: Boolean,
      default: false,
    },
    containsPhysicalGiftCard: {
      type: Boolean,
      default: false,
    },
    containsDigitalGiftCard: {
      type: Boolean,
      default: false,
    },
    containsMerchandise: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style>
.pageOrderSuccess {
  @apply my-16 pb-25 bg-cover flex flex-col justify-end;
  height: 600px;
  background-image: url('../../../assets/images/Order_Sent.png');
}
</style>
