<template>
  <div class="w-full max-w-4xl py-20 bg-white flex flex-col ">
    <Back
      :route="{ name: 'reward-boxes-selection' }"
      title="rewards.reward_boxes.selection.title"
      class="my-15 block"
    />
    <h4 class="my-16">
      {{ $t('rewards.reward_boxes.confirmation.title') }}
    </h4>
    <div v-if="!selectedRewardBox">
      <p>{{ $t('rewards.reward_boxes.confirmation.none_selected') }}</p>
    </div>
    <div v-else>
      <div class="border rounded-lg">
        <div class="flex flex-wrap border-b p-12">
          <div>
            <img class="w-300 m-12" :src="selectedRewardBox.imageUrl" />
          </div>
          <div class="flex flex-1 justify-between">
            <div class="m-12 flex-col min-w-xs">
              <h5>{{ selectedRewardBox.name }}</h5>
              <div v-html="selectedRewardBox.description" class="description" />
            </div>
            <div class="m-12 flex flex-col items-center">
              <Icon
                iconClass="fas fa-times text-4xl text-grey-500 mb-0 cursor-pointer"
                @click="clearSelectedRewardBox"
              />
              <span class="text-grey-500 -mt-8">Remove</span>
            </div>
          </div>
        </div>
        <div class="p-20 mt-12 break-all">
          <p class="text-xs font-bold">
            {{ $t('rewards.reward_confirmation.address') }}
          </p>
          <p>{{ deliveryAddress.streetAddr1 }}</p>
          <p>{{ deliveryAddress.streetAddr2 }}</p>
          <p>
            {{
              `${deliveryAddress.city}, ${deliveryAddress.state} ${deliveryAddress.zip}`
            }}
          </p>
          <p
            class="mt-12 font-semibold text-blue-500 inline-block cursor-pointer"
            @click="editInfoModal = !editInfoModal"
          >
            {{ $t('rewards.reward_confirmation.edit_address_btn') }}
          </p>
        </div>
      </div>
      <div class="text-center">
        <HMButton rounded="full" class="w-300 my-16" @click="placeOrder">
          {{ $t('rewards.reward_confirmation.confirm_btn') }}
        </HMButton>
        <br />
        <HMButton
          rounded="full"
          class="w-300"
          color="white"
          outlined
          @click="onCancel"
        >
          {{ $t('common.cancel_btn') }}
        </HMButton>
      </div>
    </div>
    <Modal v-if="editInfoModal" @close="closeEditInfoModal">
      <RewardsEditInfo @close="closeEditInfoModal" />
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import RewardsEditInfo from '../RewardsEditInfo'
import Modal from '@components/common/Modal'

export default {
  components: { Modal, RewardsEditInfo },
  data() {
    return {
      editInfoModal: false,
    }
  },
  computed: {
    ...mapGetters('rewards', [
      'currentRewardsSummary',
      'selectedRewardBox',
      'deliveryAddress',
    ]),
  },
  mounted() {
    if (!this.currentRewardsSummary.availableTokens) {
      this.$router.push({ name: 'rewards' })
    }
  },
  methods: {
    ...mapActions('rewards', [
      'redeemRewardBox',
      'initRewards',
      'invalidateRewards',
      'clearSelectedRewardBox',
    ]),

    onSuccess() {
      this.invalidateRewards()
      this.clearSelectedRewardBox()
      this.initRewards()
      this.$router.push({ name: 'reward-boxes-success' })
    },
    onCancel() {
      this.clearSelectedRewardBox()
      this.$router.push({ name: 'rewards' })
    },
    placeOrder() {
      if (!this.selectedRewardBox) return
      this.redeemRewardBox().then(this.onSuccess)
    },
    closeEditInfoModal() {
      this.editInfoModal = false
    },
  },
}
</script>

<style>
.description > ul {
  list-style: disc;
  padding-left: 40px;
}
</style>
