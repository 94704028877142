<template>
  <Card class="p-25">
    <div class="md:flex justify-between items-center md:space-y-0 space-y-20">
      <h2 class="h5">
        {{ $t('health_actions.home.gift_card', [healthActionTitle]) }}:
        <span class="text-green-500">
          {{ currentBalanceText }}
        </span>
      </h2>
      <HMButton
        :to="{
          name: 'rewards-redeem',
          query: { hacId: healthActionCompletionId },
        }"
      >
        {{ $t('rewards.landing.redeem_now') }}
      </HMButton>
    </div>
  </Card>
</template>

<script>
import _get from 'lodash/get'
import { mapGetters } from 'vuex'
import { Card } from '@components/common/card/index'

export default {
  components: { Card },
  props: { pointSummary: { type: Object, required: true } },
  computed: {
    ...mapGetters('healthActions', ['healthActionById']),
    healthActionId() {
      return _get(this.pointSummary, 'healthActionId')
    },
    healthActionCompletionId() {
      return _get(this.pointSummary, 'healthActionCompletionId')
    },
    healthAction() {
      return this.healthActionById(this.healthActionId)
    },
    healthActionTitle() {
      return _get(this.healthAction, 'title')
    },
    currentBalanceText() {
      return this.$t(`rewards.landing.current_balance.value.dollars`, [
        this.pointSummary.availablePoints,
      ])
    },
  },
}
</script>
