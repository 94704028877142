<template>
  <div>
    <Card
      class="p-25 cursor-pointer flex justify-center items-center h-full"
      @click.native="showFamilyDrawer = true"
    >
      <div class="flex justify-center items-center">
        <div class="relative">
          <div
            class="h-60 w-60 rounded-full bg-pink-100 mr-10 flex items-center justify-center"
          >
            <Family aria-hidden="true" />
          </div>
          <span
            v-if="familyRedeemableCount"
            class="absolute top-0 right-0 bg-blue-500 w-20 h-20 flex items-center justify-center rounded-full text-white text-sm font-bold"
          >
            {{ familyRedeemableCount }}
          </span>
        </div>
        <span
          class="text-blue-500 text-sm font-semibold underline whitespace-no-wrap"
        >
          {{ $t('rewards.family_rewards.view') }}
        </span>
      </div>
    </Card>

    <!-- drawer -->
    <Drawer v-model="showFamilyDrawer">
      <FamilyRewardsDrawer @closeDrawer="showFamilyDrawer = false" />
    </Drawer>
  </div>
</template>

<script>
import { Card } from '@components/common/card/index'
import Drawer from '@components/common/Drawer'
import FamilyRewardsDrawer from './FamilyRewardsDrawer'
import { Family } from '@assets/svgs'
import { mapGetters } from 'vuex'

export default {
  components: { Card, Drawer, FamilyRewardsDrawer, Family },
  data() {
    return { showFamilyDrawer: false }
  },
  computed: {
    ...mapGetters('rewards', ['familyRedeemableCount']),
  },
}
</script>
