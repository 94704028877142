<template>
  <Card class="p-25">
    <div class="md:flex justify-between items-center md:space-y-0 space-y-20">
      <CurrentBalanceHeader />
      <HMButton :to="{ name: 'rewards-redeem' }">
        {{
          canAffordRedemption
            ? $t('rewards.landing.redeem_now')
            : $t('rewards.landing.view_rewards')
        }}
      </HMButton>
    </div>
  </Card>
</template>

<script>
import CurrentBalanceHeader from './CurrentBalanceHeader.vue'
import { Card } from '@components/common/card/index'
import { mapGetters } from 'vuex'

export default {
  components: { Card, CurrentBalanceHeader },
  computed: {
    ...mapGetters('rewards', ['canAffordRedemption']),
  },
}
</script>
