<template>
  <div>
    <FormGroup
      v-slot="{ hasError }"
      :label="label"
      :name="name"
      :helperText="helperText"
    >
      <!--      TODO: fot the mask: when this https://github.com/probil/v-mask/pull/457 is merged, the v-if v-else won't be necessary -->
      <input
        v-if="mask"
        :id="name"
        ref="input"
        v-mask="mask"
        v-bind="attrs"
        :name="name"
        :value="value"
        :placeholder="placeholder"
        :type="type"
        class="w-full px-8 py-12 text-base leading-none bg-white border rounded border-grey-500 focus:outline-none focus:shadow-outline placeholder-grey-500"
        :class="{ 'border-red-500': hasError }"
        :aria-invalid="hasError.toString()"
        :required="required"
        @input="$emit('input', $event.target.value)"
      />
      <div v-else class="relative flex items-center">
        <input
          :id="name"
          ref="input"
          v-bind="attrs"
          :name="name"
          :value="value"
          :placeholder="placeholder"
          :type="inputType"
          class="w-full px-8 py-12 text-base leading-none bg-white border rounded border-grey-500 focus:outline-none focus:shadow-outline placeholder-grey-500"
          :class="{ 'border-red-500': hasError }"
          :aria-invalid="hasError.toString()"
          :required="required"
          @input="$emit('input', $event.target.value)"
        />
        <button
          v-if="secure && value.length"
          class="absolute right-0 text-xs font-semibold tracking-wide text-right cursor-pointer mr-35"
          type="button"
          @click="isSecure = !isSecure"
        >
          {{ isSecure ? $t('common.show') : $t('common.hide') }}
        </button>
      </div>
    </FormGroup>
  </div>
</template>
<script>
import FormGroup from './FormGroup'
import bindAll from '@platform-shared/mixins/bindAll'
export default {
  components: { FormGroup },
  mixins: [bindAll],
  props: {
    mask: { type: String, required: false, default: '' },
    name: {
      type: String,
      required: true,
    },
    value: { type: [String, Number], default: '' },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    hasError: {
      type: Boolean,
    },
    label: {
      type: String,
      default: '',
    },
    secure: {
      type: Boolean,
      default: false,
    },
    helperText: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isSecure: this.secure,
    }
  },
  computed: {
    inputType() {
      if (this.type === 'password') {
        return !this.isSecure ? 'text' : 'password'
      }
      return this.type
    },
  },
}
</script>
