<template>
  <div
    class="fixed h-full w-full flex top-0 left-0 overlay z-10"
    :class="{
      'justify-end': align === 'right',
      'justify-start': align === 'left',
      'pointer-events-none': !value,
      shadow: value,
    }"
  >
    <transition :name="align === 'right' ? 'slide-left' : 'slide-right'">
      <div v-if="value" ref="drawerContents" v-click-outside="closeDrawer">
        <FocusTrap :active="value" tabindex="-1">
          <slot class="content" />
        </FocusTrap>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    align: {
      type: String,
      default: 'right',
    },
  },
  watch: {
    value(isActive, wasActive) {
      if (!wasActive && isActive) {
        setTimeout(this.focusFirstElement, 1)
      }
    },
  },
  methods: {
    focusFirstElement() {
      const focusable = this.$refs.drawerContents.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      )
      if (focusable.length > 0) focusable[0].focus()
    },
    closeDrawer() {
      this.$emit('input', false)
    },
  },
}
</script>

<style scoped>
.overlay {
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 500ms linear;
}
.shadow {
  background-color: rgba(0, 0, 0, 0.25);
}
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  transition: transform 500ms ease-in-out;
}
.slide-left-enter,
.slide-left-leave-to {
  transform: translate3D(100%, 0, 0);
}
.slide-right-enter,
.slide-right-leave-to {
  transform: translate3D(-100%, 0, 0);
}
</style>
