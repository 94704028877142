<template>
  <form @submit.prevent="$emit('submit')">
    <slot />
    <div class="text-center">
      <FormError v-if="!!error" :text="error" />
    </div>
    <slot name="button" />
  </form>
</template>

<script>
import FormError from './FormError'
export default {
  name: 'Form',
  components: {
    FormError,
  },
  props: {
    validator: {
      type: Object,
      required: true,
    },
    messages: {
      type: Object,
      default: () => ({}),
    },
    error: {
      type: String,
      default: null,
    },
  },
  provide() {
    return {
      formValidator: this.validator,
      formMessages: this.messages,
    }
  },
}
</script>
