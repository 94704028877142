<template>
  <p
    class="px-16 py-6 mx-10 mt-10 text-sm leading-none text-center text-white rounded-full inline-block"
    :class="color"
  >
    {{ $t(text) }}
  </p>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'bg-blue-500',
    },
  },
}
</script>
