<template>
  <div v-if="maxEarnablePoints > 0">
    <p>
      {{
        $t('rewards.landing.rewards_earned_none', [
          formatCurrency(totalPointsEarned),
          formatCurrency(maxEarnablePoints),
        ])
      }}
    </p>

    <ProgressBar class="mt-10" gradient :percentage="earnedPercentage" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import rewardsCurrency from '@platform-shared/mixins/rewardsCurrency'
import ProgressBar from '@components/common/ProgressBar'

export default {
  components: { ProgressBar },
  mixins: [rewardsCurrency],
  computed: {
    ...mapGetters('rewards', ['currentRewardsSummary']),

    maxEarnablePoints() {
      const { maxPoints = 0 } = this.currentRewardsSummary
      return maxPoints
    },

    totalPointsEarned() {
      const { totalPoints = 0 } = this.currentRewardsSummary
      return totalPoints
    },

    earnedPercentage() {
      return Math.round((this.totalPointsEarned / this.maxEarnablePoints) * 100)
    },
  },
}
</script>
