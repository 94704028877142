<template>
  <div class="bg-white min-h-screen">
    <Header />
    <img
      role="presentation"
      class="w-screen"
      src="../../../assets/images/auto-rewards-splash.png"
      :alt="$t('rewards.automatic_rewards.title')"
    />
    <div
      class="container p-20 flex-grow flex-col flex text-center justify-center"
    >
      <h3 class="mb-10">
        {{ $t('rewards.automatic_rewards.title') }}
      </h3>
      <p class="text-sm md:max-w-1/2 self-center">
        {{ $t('rewards.automatic_rewards.splash.description') }}
      </p>
      <h6 class="mt-35">
        {{ $t('rewards.automatic_rewards.splash.instructions') }}
      </h6>

      <div class="flex flex-col md:flex-row w-full mt-35">
        <div class="flex flex-row flex-grow justify-start md:justify-center ">
          <h6 class="rounded-full bg-blue-500 text-white pt-4 mr-10 w-35 h-35">
            1
          </h6>
          <p class="text-sm md:max-w-1/2 self-center text-left">
            {{ $t('rewards.automatic_rewards.splash.step_1') }}
          </p>
        </div>
        <div
          class="flex flex-row flex-grow justify-start mt-12 md:justify-center md:mt-0"
        >
          <h6 class="rounded-full bg-blue-500 text-white pt-4 mr-10 w-35 h-35">
            2
          </h6>
          <p class="text-sm md:max-w-1/2 self-center text-left">
            {{ $t('rewards.automatic_rewards.splash.step_2') }}
          </p>
        </div>
        <div
          class="flex flex-row flex-grow justify-start mt-12 md:justify-center md:mt-0"
        >
          <h6 class="rounded-full bg-blue-500 text-white pt-4 mr-10 w-35 h-35">
            3
          </h6>
          <p class="text-sm md:max-w-1/2 self-center text-left">
            {{ $t('rewards.automatic_rewards.splash.step_3') }}
          </p>
        </div>
      </div>

      <div class="flex flex-col mt-40">
        <HMButton
          class="w-full md:w-1/2  lg:w-1/3 mt-20 self-center"
          @click="$router.push('/rewards/auto/setup')"
        >
          {{ $t('common.next_btn') }}
        </HMButton>

        <HMButton
          class="w-full md:w-1/2 lg:w-1/3 mt-20 self-center border border-grey-300"
          color="white"
          @click="$router.push('/rewards')"
        >
          {{ $t('common.cancel_btn') }}
        </HMButton>
      </div>
    </div>
  </div>
</template>
<script>
import Header from '../../common/Header'
export default {
  components: { Header },
}
</script>
