<template>
  <div class="py-12 px-5 m-auto">
    <div class="flex items-center">
      <div class="flex items-center">
        <img
          :src="item.reward.imageUrl"
          aria-hidden="true"
          height="43px"
          width="72px"
          :alt="item.reward.name"
        />
        <div class="mx-10">
          <h6 class="-mb-4">
            {{ `$${item.reward.faceValue} ${item.reward.name}` }}
          </h6>
          <p class="text-sm">
            {{
              $t(
                item.reward.type === 'MERCHANDISE'
                  ? 'rewards.item_basket.merchandise_quantity'
                  : `rewards.item_basket.${item.reward.deliveryForm}`,
                [item.quantity]
              )
            }}
          </p>
          <button
            class="text-blue-500 underline font-semibold text-sm"
            @click="showDescription = !showDescription"
          >
            {{
              $t(
                `rewards.item_basket.${
                  showDescription ? 'hide' : 'view'
                }_description`
              )
            }}
          </button>
        </div>
      </div>
      <button
        class="text-center ml-auto cursor-pointer rounded-md p-4 text-grey-500"
        @click="removeReward({ reward: item.reward, healthActionCompletionId })"
      >
        <i aria-hidden="true" class="fas fa-times text-sm" />
        <p class="text-xs">{{ $t('rewards.item_basket.remove') }}</p>
      </button>
    </div>
    <div
      v-if="showDescription"
      class="mt-10 description"
      v-html="item.reward.description"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: { item: { type: Object, required: true } },
  data() {
    return {
      showDescription: false,
    }
  },
  computed: {
    healthActionCompletionId() {
      return this.$route.query.hacId
    },
  },
  methods: {
    ...mapActions('rewards', ['removeReward']),
  },
}
</script>

<style>
.description > ul {
  list-style: disc;
  padding-left: 40px;
}
</style>
