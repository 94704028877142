<template>
  <div class="w-full rounded-full bg-grey-200">
    <div
      class="h-10 rounded-full"
      :class="{
        'md:h-20': !small,
        'orange-gradient': gradient,
        'bg-green-500': !gradient,
      }"
      :style="`width: ${clampedPercentage}%`"
    />
  </div>
</template>

<script>
import _clamp from 'lodash/clamp'

export default {
  props: {
    percentage: {
      type: Number,
      default: 2,
    },
    small: {
      type: Boolean,
      default: false,
    },
    gradient: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    clampedPercentage() {
      return _clamp(this.percentage, 4, 100)
    },
  },
}
</script>

<style>
.orange-gradient {
  background: linear-gradient(270deg, #ffc122 0%, #ff9211 99.99%, #ff8a00 100%);
}
</style>
