<template>
  <Card class="p-25">
    <h2 class="h5">{{ $t('rewards.landing.rewards_history') }}</h2>
    <EarnedProgressBar />
    <div
      v-for="(transactions, date) in nonZeroTransactionDates"
      :key="date"
      class="my-20"
    >
      <H3 class="font-bold">{{ formatDate(date) }}</H3>
      <RewardsHistoryTransactionCard
        v-for="(transaction, idx) in transactions"
        :key="`transaction-${date}-${idx}`"
        :transaction="transaction"
        class="border-b"
      />
    </div>
  </Card>
</template>

<script>
import { mapGetters } from 'vuex'
import _pickBy from 'lodash/pickBy'
import _some from 'lodash/some'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import RewardsHistoryTransactionCard from './RewardsHistoryTransactionCard'
import EarnedProgressBar from './EarnedProgressBar'
import { Card } from '@components/common/card/index'

export default {
  components: { RewardsHistoryTransactionCard, EarnedProgressBar, Card },
  computed: {
    ...mapGetters('rewards', ['rewardsHistoryByDate']),
    nonZeroTransactionDates() {
      return _pickBy(this.rewardsHistoryByDate, (transactions) =>
        _some(transactions, 'amount')
      )
    },
  },
  methods: {
    formatDate(date) {
      return date ? format(parseISO(date), 'M/d/yyyy') : ''
    },
  },
}
</script>
